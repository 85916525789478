<!--<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin" [fullScreen] = "true"><p style="color: white" > Cargando... </p></ngx-spinner>-->
<div class="p-4"></div>
<div class="container pt-lg-5">
    <section class="row mb-3">
        <h2 class="mb-5 col display-4 text-xl font-weight-bold">Mis Animes: </h2>
        <div class="row h-25 ml-2">
            <select class="form-control col-5 col-md" [formControl]="estadoSelectFiltro">
                <option selected [selected]="true" [value]="0">...</option>
                <option *ngFor="let estadoOption of EstadosArray" [value]="estadoOption.IdEstado">{{estadoOption.NombreEstado}}</option>
            </select>
            <select class="ml-2" [formControl]="ordenFiltro">
                <option value="1">Orden Ascendente</option>
                <option value="2" [selected]="true">Orden Descendente</option>
            </select>
            <button class="btn btn-info ml-2 mr-sm-2" (click)="filtroEstado(); filtroOrden()">
                <i class="fas fa-filter"></i>
                Filtrar
            </button>
        </div>
    </section>
    
    <section class="row justify-content-center justify-content-lg-start">
        <div type="button" class="card col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 p-0 ml-3 ml-sm-5" *ngFor="let anime of animeAuxArry | paginate: { itemsPerPage: 8, currentPage: p }">
            <a [routerLink]="['/Anime', anime.IdAnime, anime.NombreAnime]">
            <div class="ribbon-wrapper ribbon-lg">
                <div class="ribbon bg-success text-sm" *ngFor="let estado of EstadosArray" [ngClass]="{'d-none' : estado.IdEstado!=anime.Estado,
                 'bg-danger' : anime.Estado==4, 'bg-info' : anime.Estado==3, 'bg-warning' : anime.Estado==2}">
                    <small class="font-weight-bold">{{estado.NombreEstado}}</small>
                </div>
            </div>
            <div class="card-header p-0">
                <img class="img-fluid imgAnime rounded-top w-100" src="{{hostName}}/animes3.0.1API/{{anime.img}}" alt="img"/>
            </div>
            <div class="card-body text-dark">
                <h2>{{anime.NombreAnime}}</h2>
            </div>
            </a>
        </div>
    </section>
    <section class="row justify-content-center mt-5">
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </section>
</div>