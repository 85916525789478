<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-dark-primary elevation-4">
  <!-- Brand Logo -->
  <a [routerLink]="['/']"  class="brand-link">
    <img src="assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3"
         style="opacity: .8">
    <span class="brand-text font-weight-bold text-coral"><b class="text-white">Anime</b>LS</span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user panel (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img src="{{hostPrincipal}}/animes3.0.1API/{{this.usuarioSelect.img}}" class="img-circle elevation-2" alt="User Image">
      </div>
      <div class="info dropdown">
        <a href="#" class="d-block dropdown-toggle" role="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{this.usuarioSelect.Nombre}}</a>
        <div class="dropdown-menu menuEspecialUsuario" aria-labelledby="dropdownMenuButton">
            <a [routerLink]="['/Perfil']"  class="dropdown-item"><i class="fas fa-user mr-2"></i> Perfil</a>
            <div class="dropdown-divider"></div>
            <a href="javascript:void(0)" class="dropdown-item" data-toggle="modal" data-target="#modalCerrarSession"><i class="fas fa-sign-out-alt mr-2"></i> Cerrar Sesion</a>
        </div>
      </div>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
        <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->
        <li class="nav-item">
          <a [routerLink]="['/']" class="nav-link">
            <i class="nav-icon fas fa-home"></i>
            <p>
              Inicio
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/Insertar']" class="nav-link">
            <i class="nav-icon fas fa-plus"></i>
            <p>
              Insertar Anime
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/AnimesUsuarios']" class="nav-link">
            <i class="nav-icon fas fa-list"></i>
            <p>
              Animes Usuarios
            </p>
          </a>
        </li>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>
<app-cerrar-session-modal (clickYes)="cerrarSession()"></app-cerrar-session-modal>