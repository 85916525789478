import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-drop-anime-modal',
  templateUrl: './drop-anime-modal.component.html',
  styleUrls: ['./drop-anime-modal.component.css']
})
export class DropAnimeModalComponent implements OnInit 
{
  @Output() clickYes = new EventEmitter();
  constructor() { }

  ngOnInit(): void 
  {
  }

  clickYesfunction()
  {
    this.clickYes.emit();
  }

}
