import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Anime } from 'src/app/clases/anime';
import { host } from 'src/app/data';

@Injectable({
  providedIn: 'root'
})
export class AnimeService {

  private url = host+"/animes3.0.1API/Model/animes/";

  constructor(private http : HttpClient) { }

  seleccionar(anime : Anime)
  {
    return this.http.post(`${this.url}seleccionarAnimes.php`, JSON.stringify(anime));
  }

  SeleccionarAnimeUsers(anime : Anime)
  {
    return this.http.post(`${this.url}SeleccionarAnimeUsuers.php`, JSON.stringify(anime));
  }

  Insertar(anime : Anime)
  {
    return this.http.post(`${this.url}InsertarAnime.php`,JSON.stringify(anime));
  }

  Update(anime : Anime)
  {
    return this.http.post(`${this.url}UpdateAnime.php`,JSON.stringify(anime));
  }

  Delete(anime : Anime)
  {
    return this.http.post(`${this.url}DeleteAnime.php`,JSON.stringify(anime));
  }
}
