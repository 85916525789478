<div class="modal fade" id="modalUpdateAnimeUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-dark">
          <h5 class="modal-title" id="exampleModalLongTitle">Update</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label>Estado: </label>
                <select  class="form-control" name="selectdEstados" [(ngModel)]="animeUsuario.Estado">
                    <option *ngFor="let estado of EstadoArray" [value]="estado.IdEstado">{{estado.NombreEstado}}</option>
                </select>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-info" (click)="update()">Actualizar</button>
        </div>
      </div>
    </div>
</div>