import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavTopComponent } from './Layouts/nav-top/nav-top.component';
import { SideBarLeftComponent } from './Layouts/side-bar-left/side-bar-left.component';
import { FooterPagComponent } from './Layouts/footer-pag/footer-pag.component';
import { Error404Component } from './Layouts/error404/error404.component';
import { InicioComponent } from './Layouts/inicio/inicio.component';
import { LoginComponent } from './Layouts/login/login.component';
import { RegistroComponent } from './Layouts/registro/registro.component';
import { ControlSidebarComponent } from './Layouts/control-sidebar/control-sidebar.component';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PerfilUserComponent } from './Layouts/perfil-user/perfil-user.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import {NgxPaginationModule} from 'ngx-pagination';
import { SearchComponent } from './Layouts/search/search.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { AnimeComponent } from './Layouts/anime/anime.component';
import { ModalUpdateAnimeComponent } from './Layouts/modales/modal-update-anime/modal-update-anime.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { InsertarAnimeComponent } from './Layouts/insertar-anime/insertar-anime.component';
import { DropAnimeModalComponent } from './Layouts/modales/drop-anime-modal/drop-anime-modal.component';
import { AnimeAmigosComponent } from './Layouts/anime-amigos/anime-amigos.component';
import { PerfilAnimeUserComponent } from './Layouts/perfil-anime-user/perfil-anime-user.component';
import { ModalUpdateAnimeAmigoComponent } from './Layouts/modales/modal-update-anime-amigo/modal-update-anime-amigo.component';
import { InsertarAnimeUserMoadalComponent } from './Layouts/modales/insertar-anime-user-moadal/insertar-anime-user-moadal.component';
import { CreateListaModalComponent } from './Layouts/modales/create-lista-modal/create-lista-modal.component';
import { DropListModalComponent } from './Layouts/modales/drop-list-modal/drop-list-modal.component';
import { PerfilListaComponent } from './Layouts/perfil-lista/perfil-lista.component';
import { ModalListAddComponent } from './Layouts/modales/modal-list-add/modal-list-add.component';
import { CerrarSessionModalComponent } from './Layouts/modales/cerrar-session-modal/cerrar-session-modal.component';
import { ForgetPasswordComponent } from './Layouts/forget-password/forget-password.component';
import {CookieService} from 'ngx-cookie-service';
import { CookiesMessajeComponent } from './Layouts/cookies-messaje/cookies-messaje.component';
import { TextoLegalCokiesComponent } from './Layouts/texto-legal-cokies/texto-legal-cokies.component';
import { ConfigurarCookiesComponent } from './Layouts/configurar-cookies/configurar-cookies.component';

@NgModule({
  declarations: [
    AppComponent,
    NavTopComponent,
    SideBarLeftComponent,
    FooterPagComponent,
    Error404Component,
    InicioComponent,
    LoginComponent,
    RegistroComponent,
    ControlSidebarComponent,
    PerfilUserComponent,
    SearchComponent,
    AnimeComponent,
    ModalUpdateAnimeComponent,
    InsertarAnimeComponent,
    DropAnimeModalComponent,
    AnimeAmigosComponent,
    PerfilAnimeUserComponent,
    ModalUpdateAnimeAmigoComponent,
    InsertarAnimeUserMoadalComponent,
    CreateListaModalComponent,
    DropListModalComponent,
    PerfilListaComponent,
    ModalListAddComponent,
    CerrarSessionModalComponent,
    ForgetPasswordComponent,
    CookiesMessajeComponent,
    TextoLegalCokiesComponent,
    ConfigurarCookiesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      timeOut: 1500,
      preventDuplicates: false
    }),
    HttpClientModule,
    FormsModule,
    SweetAlert2Module,
    NgxDropzoneModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    CKEditorModule
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
