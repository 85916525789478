import { Component, OnInit } from '@angular/core';
import { Estado } from 'src/app/clases/estado';
import * as clasicEditor from '@ckeditor/ckeditor5-build-classic';
import { EstadoService } from 'src/app/Servicios/Estados/estado.service';
import { GestorImagenesUserServiceService } from 'src/app/Servicios/img/gestor-imagenes-user-service.service';
import { Anime } from 'src/app/clases/anime';
import { ToastrService } from 'ngx-toastr';
import { AnimeService } from 'src/app/Servicios/animes/anime.service';
import { keyEncrypt } from 'src/app/data';
import { SimpleCrypt } from 'ngx-simple-crypt';

@Component({
  selector: 'app-insertar-anime',
  templateUrl: './insertar-anime.component.html',
  styleUrls: ['./insertar-anime.component.css']
})
export class InsertarAnimeComponent implements OnInit 
{
  public encrypy = new SimpleCrypt();
  public AnimeInsert : Anime = new Anime();
  EstadoSelect : Estado = new Estado();
  EstadoArray : Estado[] = [];
  public imgFilePreview : File[] = [];
  private imgFile : File;
  public editor = clasicEditor;

  public toolbarConfig = {
    toolbar: [ 'heading', '|', 'bold', 'italic']
  };

  constructor(private gestorImg : GestorImagenesUserServiceService,
    private estadosService : EstadoService,
    private toastr : ToastrService,
    private animeService : AnimeService) { }

  ngOnInit(): void 
  {
    this.cargarEstados();
    this.AnimeInsert.DescripcionAnime="";
  }

  cargarEstados()
  {
    this.EstadoSelect.IdEstado=0;
    this.estadosService.Seleccionar(this.EstadoSelect).subscribe((x: Estado[])=>this.EstadoArray = x);
  }

  submit()
  {
    this.SubirImg();
  }

  SubirImg()
  {
    if(this.AnimeInsert.NombreAnime!="" && this.AnimeInsert.DescripcionAnime!="" && this.AnimeInsert.Estado>0)
    {
      if(this.imgFile)
      {
        this.gestorImg.subirImagen(this.imgFile).subscribe((x : string)=>{
          this.AnimeInsert.img=x;
          this.subirDatosAnimeUsuario();
        });
      }
      else
      this.toastr.error("Tienes que seleccionar una imagen.");
    }
    else
      this.toastr.error("Tienes que introducir valores en todos los campos.");
  }

  subirDatosAnimeUsuario()
  {
    this.AnimeInsert.Usuario=this.encrypy.decode(keyEncrypt,sessionStorage.getItem('egs'));
    this.animeService.Insertar(this.AnimeInsert).subscribe(x=>{
      if(x==true)
      {
        this.toastr.success("Se inserto correctamente.");
        this.clear();
      }
      else
      {
        this.toastr.error("Hubo algun problema al insertar datos, Revise que no halla caracteres como: (')");
        this.gestorImg.BorrarImagen(this.AnimeInsert.img).subscribe();
      }
    });
  }

  imagenUpdate(event)
  {
    if(this.imgFilePreview.length>0)
      this.imagenRemove();
    this.imgFilePreview.push(...event.addedFiles);
    this.imgFile=this.imgFilePreview[0];
  }

  imagenRemove()
  {
    this.imgFilePreview = [];
    this.imgFile=undefined;
  }

  clear()
  {
    this.AnimeInsert.NombreAnime="";
    this.AnimeInsert.DescripcionAnime="";
    this.imagenRemove();
  }

}
