<div class="container pt-5">
    <section class="row ml-2 mb-2">
        <p class="text-lg">El resultado para la busqueda de <strong>'{{Busqueda}}'</strong> es: </p>
    </section>
    <section class="row">
        <div type="button" class="card col-7 col-sm-6 col-md-4 col-lg-3 col-xl-2 p-0 ml-5" *ngFor="let anime of animeAuxArry | paginate: { itemsPerPage: 8, currentPage: p }"
            >
            <a [routerLink]="['/Anime', anime.IdAnime, anime.NombreAnime]">
            <div class="ribbon-wrapper ribbon-lg">
                <div class="ribbon bg-success text-sm" *ngFor="let estado of EstadosArray" [ngClass]="{'d-none' : estado.IdEstado!=anime.Estado,
                 'bg-danger' : anime.Estado==4, 'bg-info' : anime.Estado==3, 'bg-warning' : anime.Estado==2}">
                    <small class="font-weight-bold">{{estado.NombreEstado}}</small>
                </div>
            </div>
            <div class="card-header p-0">
                <img class="img-fluid imgAnime rounded-top w-100" src="{{hostName}}/animes3.0.1API/{{anime.img}}" alt="img"/>
            </div>
            <div class="card-body text-dark">
                <h2>{{anime.NombreAnime}}</h2>
            </div>
            </a>
        </div>
    </section>
    <section class="row justify-content-center mt-5">
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </section>
</div>
