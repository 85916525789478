<div class="container-fluid p-4 pt-3">
    <section class="row">
        <div class="col-6 p-0">
            <h2 class="mb-5 display-4 text-lg">Insertar nuevo Anime: </h2>
        </div>
        <div class="col-6">
            <ol class="breadcrumb bg-fondoGris float-sm-right">
                <li class="breadcrumb-item"><a [routerLink]="['/']">Inicio</a></li>
                <li class="breadcrumb-item active">Insertar</li>
              </ol>
        </div>
    </section>
    <div class="card card-primary card-outline">
        <div class="card-body">
            <form class="form-horizontal" (submit)="submit()">
                <div class="form-group row">
                  <label for="inputName" class="col-sm-2 col-form-label">Nombre Anime</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" name="nombreAnime" [(ngModel)]="AnimeInsert.NombreAnime" placeholder="Nombre Anime" required>
                    <a href="javascript:void(0)" data-toggle="modal" data-target="#modalInsertuserAnime">¿Quieres el anime de otro usuario?</a>
                  </div>
                </div>
                <div class="col"><hr></div>
                <div class="form-group row">
                  <label for="inputEmail" class="col-sm-2 col-form-label">Descripcion</label>
                  <div class="col-sm-10">
                    <ckeditor [editor]="editor" name="editor" [(ngModel)]="AnimeInsert.DescripcionAnime" [config]="toolbarConfig"></ckeditor>
                  </div>
                </div>
                <div class="form-group row">
                    <label for="inputState" class="col-sm-2 col-form-label">Estado</label>
                    <select class="form-control col-sm-10" name="estado" [(ngModel)]="AnimeInsert.Estado">
                      <option *ngFor="let estado of EstadoArray" [value]="estado.IdEstado">{{estado.NombreEstado}}</option>
                    </select>
                </div>
                <div class="col"><hr></div>
                <div class="form-group row">
                  <label for="inputSkills" class="col-sm-2 col-form-label">Img</label>
                  <ngx-dropzone class="col-sm-10" (change)="imagenUpdate($event)">
                    <ngx-dropzone-label>Arrastra tu imagen aqui</ngx-dropzone-label>
                    <ngx-dropzone-image-preview *ngFor="let f of imgFilePreview" [removable]="true" (removed)="imagenRemove()" [file]="f">
                        <ngx-dropzone-label class="text-white">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-image-preview>
                  </ngx-dropzone>
                </div>
                <div class="form-group row m-0">
                    <div class="col-6">
                        <div type="button" [routerLink]="['/']"  class="btn btn-danger">Atras</div>
                    </div>
                    <div class="col-6 text-right">
                        <button type="submit" class="btn btn-info">Insertar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<app-insertar-anime-user-moadal></app-insertar-anime-user-moadal>