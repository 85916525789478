<div class="row m-0 p-3">
    <h2 class="col display-4 text-xl">Animes Usuarios: </h2>
    <div class="m-0">
        <ol _ngcontent-xvq-c79 class="breadcrumb bg-fondoGris float-sm-right">
            <li _ngcontent-xvq-c79 class="breadcrumb-item">
                <a _ngcontent-xvq-c79 ng-reflect-router-link="/" href="/">Inicio</a>
            </li>
            <li _ngcontent-xvq-c79="" class="breadcrumb-item active">AnimesUsuarios</li>
        </ol>
    </div>
</div>
<div class="container">
    <section class="row justify-content-center justify-content-lg-start">
        <aside class="col-12 row mb-5">
            <form class="col col-lg-4 ml-md-5 mr-5">
                <div>
                    <input placeholder="text" name="search" placeholder="Search" class="form-control form-control-navbar" (input)="search($event.target.value)"/>
                    <i class="fas fa-search search"></i>
                </div>
            </form>
            <div class="ml-2 row h-25">
                <select class="form-control col" [formControl]="estadoSelectFiltro">
                    <option selected [selected]="true" [value]="0">...</option>
                    <option *ngFor="let estadoOption of estadoArray" [value]="estadoOption.IdEstado">{{estadoOption.NombreEstado}}</option>
                </select>
                <select class="ml-2" [formControl]="ordenFiltro">
                    <option value="1">Orden Ascendente</option>
                    <option value="2" [selected]="true">Orden Descendente</option>
                </select>
                <button class="btn btn-info ml-2" (click)="filtroEstado(); filtroOrden()">
                    <i class="fas fa-filter"></i>
                    Filtrar
                </button>
            </div>
        </aside>
        <div type="button" class="card col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 p-0 ml-3 ml-sm-5" *ngFor="let anime of animesAmigosArray | paginate: { itemsPerPage: 8, currentPage: p }">
            <a class="text-dark" [routerLink]="['/AnimeUsario', anime.id, anime.Nombre]">
                <div class="ribbon-wrapper ribbon-lg">
                    <div class="ribbon bg-success text-sm" *ngFor="let estado of estadoArray" [ngClass]="{'d-none' : estado.IdEstado!=anime.Estado,
                     'bg-danger' : anime.Estado==4, 'bg-info' : anime.Estado==3, 'bg-warning' : anime.Estado==2}">
                        <small class="font-weight-bold">{{estado.NombreEstado}}</small>
                    </div>
                </div>
                <div class="card-header p-0">
                    <img *ngIf="anime.img" class="img-fluid imgAnime rounded-top w-100" src="{{hostName}}/animes3.0.1API/{{anime.img}}" alt="img"/>
                    <img *ngIf="!anime.img" class="img-fluid imgAnime rounded-top w-100" src="{{hostName}}/animes3.0.1API/users/img/a-single-tree-on-top-of-a-cliff-under-a-starry-sky_BsXg2H2FLPe.jpg" alt="img"/>
                </div>
                <div class="card-body">
                    <h2>{{anime.Nombre}}</h2>
                    <p><strong>De:</strong> {{anime.Propietario}}</p>
                </div>
            </a>
        </div>
    </section>
    <section class="row justify-content-center mt-5">
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </section>
</div>
