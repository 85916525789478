import { Component, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnimeService } from 'src/app/Servicios/animes/anime.service';
import { EstadoService } from 'src/app/Servicios/Estados/estado.service';
import { Anime } from 'src/app/clases/anime';
import { Estado } from 'src/app/clases/estado';
import { HelperSearchService } from 'src/app/Servicios/conmutadores/helper-search.service';
import { host, keyEncrypt } from 'src/app/data';
import { SimpleCrypt } from 'ngx-simple-crypt';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  private encrypy = new SimpleCrypt();
  public animesArray : Anime[];
  public animeAuxArry : Anime[] = [];
  public animeSeleted : Anime = new Anime();
  private estado : Estado = new Estado();
  public EstadosArray : Estado[];
  p: number;
  public Busqueda : string;
  constructor(private route : ActivatedRoute, private animeService : AnimeService,
              private EstadoService : EstadoService,
              private SearchHelper : HelperSearchService) { }
  public hostName=host;

  ngOnInit(): void 
  {
    this.SearchHelper.msgObserbable$.subscribe((x : string)=>{
      this.Busqueda=x;
      this.cargarAnimes();
      this.p=1;
    });
    this.CargarEstados();
  }

  cargarAnimes()
  {
    this.animeSeleted.Usuario=this.encrypy.decode(keyEncrypt,sessionStorage.getItem('egs'));
    this.animeSeleted.Estado=0;
    this.animeSeleted.IdAnime=0;
    this.animeService.seleccionar(this.animeSeleted).subscribe((x : Anime[])=>{
      this.animesArray=x;
      this.cargarArrayAux();
      this.searchFunction();
    });
  }

  cargarArrayAux()
  {
    this.animeAuxArry=this.animesArray;
  }

  public CargarEstados()
  {
    this.estado.IdEstado=0;
    this.EstadoService.Seleccionar(this.estado).subscribe((x : Estado[])=>this.EstadosArray=x);
  }

  searchFunction()
  {
    this.animeAuxArry=this.animesArray.filter(x=>x.NombreAnime.toUpperCase().indexOf(this.Busqueda.toUpperCase()) !==-1);
  }

}
