<!-- Modal -->
<div class="modal fade" id="ConfigurarCookieModal" tabindex="-1" role="dialog" aria-labelledby="configurarCookieModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Configurar Cookies.</h5>
         <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>-->
        </div>
        <div class="modal-body">
          <p>Selecione la opcion que prefiera para navegar por animels.es:</p>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="checkObligatorio" checked disabled/>
            <label class="form-check-label" for="exampleCheck1">Funcional (Obligatorio)</label>
            <p><i class="fas fa-check text-green"></i> Perdurar Sesion de usuario en nuestro sitio web.</p>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="btn btn-primary" (click)="clickGuardar()"  data-dismiss="modal">Guardar</button>
        </div>
      </div>
    </div>
  </div>
