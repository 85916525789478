import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies-messaje',
  templateUrl: './cookies-messaje.component.html',
  styleUrls: ['./cookies-messaje.component.css']
})
export class CookiesMessajeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  cookieAcept()
  {
    localStorage.setItem("cookie","true");
  }

  cookieDeniegue() //Posible disfuncion
  {
    localStorage.setItem("cookie","false");
    document.getElementById("cajacookies").classList.add("d-none");
  }

  cookieMessajeStatus()
  {
    if(localStorage.getItem("cookie") == "true" || window.location.href.search("Login")>-1 || window.location.href.search("registro")>-1)
      return false;
    return true;
  }
}
