import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit
{
  public sessionLoad = false;
  @Input() session = sessionStorage.getItem('egs');
  constructor(private router : Router,
              private cookie : CookieService){}

  ngOnInit() : void
  {
    this.sessionState();
  }

  sessionState()
  {
    if(this.cookie.check('eg&'))
    {
      sessionStorage.setItem('egs',this.cookie.get('eg&'));
      this.sessionLoad=true;
    }
    else
    {
      this.sessionLoad=false;
      this.router.navigate(['Login']);
      localStorage.removeItem("cookie");
    }
  }
}
