import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class HelperServiceService 
{
  clicked : boolean;
  private clickadoSource = new Subject<boolean>(); 
  public clickObserbable$ = this.clickadoSource.asObservable();
  constructor() { }

  clickCheck(click : boolean) {
    this.clicked=click;
    this.clickadoSource.next(click);
  }
}
