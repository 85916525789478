<div class="modal fade" id="modalAddList" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm p-0" role="document">
      <div class="modal-content">
        <div class="modal-body p-0">
          <h2 class="p-2 m-0 text-center bg-dark text-light">Mis listas</h2>
          <ul class="list-group list-group-flush m-0 p-0 list-personalizada scrollPersonalizado">
            <li *ngFor="let lista of listasArray" class="list-group-item m-0">
                <div class="form-check form-check-inline" >
                    <input class="form-check-input" type="checkbox" [checked]="comprobarChek(lista.idLista)" (change)="dropAddToList(lista,$event.target.checked)">
                    <label class="form-check-label" for="inlineCheckbox1">{{lista.NombreLista}}</label>
                </div>
            </li>
          </ul>
          <a class="nav-link mb-0" href="javascript:void(0)" data-toggle="modal" data-target="#crearLista" data-dismiss="modal">Crear nueva lista</a>
        </div>
        <div class="modal-footer p-0 m-2 border-0">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
</div>
<app-create-lista-modal (insertEvente)="ngOnInit()"></app-create-lista-modal>