<div class="modal fade" id="modalInsertuserAnime" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
            <h4 class="mb-3">Insertar Anime usuario.</h4>
            <input type="text" class="form-control" name="textAnime" (input)="search()" [(ngModel)]="textSearch" placeholder="Nombre Anime"/>
            <ul class="list-group p-0 scrollPersonalizado" style="height: 200px; overflow-y: scroll;" *ngIf="arrayAxu">
              <li class="list-group-item d-flex align-items-center m-0 p-0" *ngFor="let anime of arrayAxu" >
                <img *ngIf="anime.img" src="{{hostName}}/animes3.0.1API/{{anime.img}}" class="img-fluid imgSelect m-0" alt="img"/>
                <img *ngIf="!anime.img" src="{{hostName}}/animes3.0.1API/users/img/a-single-tree-on-top-of-a-cliff-under-a-starry-sky_BsXg2H2FLPe.jpg" class="img-fluid imgSelect mr-1" alt="img"/>
                <p class="textList">{{anime.NombreAnime}}</p>
                <button type="button" class="btn btn-primary btnAdd" (click)="insertarAnime(anime)" *ngIf="!comprobante(anime)">Añadir</button>
                <button type="button" class="btn btn-success btnAdd" *ngIf="comprobante(anime)" (click)="borrarAnime(anime)"><i class="fas fa-check"></i> Añadido</button>
              </li>
            </ul>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>