<div class="modal fade" id="crearLista" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
            <h4>Crear Nueva Lista</h4>
            <input type="text" name="NombreList" [(ngModel)]="NombreLista" class="form-control" placeholder="Nombre Lista"/>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-info" (click)="CrearLista()">Crear</button>
        </div>
      </div>
    </div>
</div>
