import { Component, OnInit } from '@angular/core';
import { AnimeService } from 'src/app/Servicios/animes/anime.service';
import { Anime } from 'src/app/clases/anime';
import { AnimeAmigosBD } from 'src/app/clases/anime-amigos-bd';
import { AnimeAmigosService } from 'src/app/Servicios/animesAmigos/anime-amigos.service';
import { ToastrService } from 'ngx-toastr';
import { host, keyEncrypt } from 'src/app/data';
import { SimpleCrypt } from 'ngx-simple-crypt';

@Component({
  selector: 'app-insertar-anime-user-moadal',
  templateUrl: './insertar-anime-user-moadal.component.html',
  styleUrls: ['./insertar-anime-user-moadal.component.css']
})
export class InsertarAnimeUserMoadalComponent implements OnInit {

  private encrypy = new SimpleCrypt();
  animeSelected : Anime = new Anime();
  arrayAnimes : Anime[];
  arrayAxu : Anime[];
  textSearch : string;
  arraryAnimesAmigo : AnimeAmigosBD[] = [];
  animeUserSelect : AnimeAmigosBD = new AnimeAmigosBD();
  public hostName=host;

  constructor(private animeService : AnimeService,
              private animeUserService : AnimeAmigosService,
              private toastr : ToastrService) { }

  ngOnInit(): void 
  {
    this.cargarAnimes();
    this.cargarArrayAuxAmigo();
  }

  cargarAnimes()
  {
    this.animeSelected.Usuario=this.encrypy.decode(keyEncrypt,sessionStorage.getItem('egs'));
    this.animeService.SeleccionarAnimeUsers(this.animeSelected).subscribe((x:Anime[])=>this.arrayAnimes=x);
  }

  cargarArrayAux()
  {
    this.arrayAxu=this.arrayAnimes;
  }

  insertarAnime(anime : Anime)
  {
    this.animeUserSelect.Nombre=anime.NombreAnime;
    this.animeUserSelect.Usuario=anime.Usuario;
    this.animeUserSelect.Propietario=this.encrypy.decode(keyEncrypt,sessionStorage.getItem('egs'));
    this.animeUserService.insert(this.animeUserSelect).subscribe(x=>{
      if(x==true)
      {
        this.cargarArrayAuxAmigo();
        this.toastr.success("Se inserto correctamente");
      }
      else
        this.toastr.error("Se produjo algun error al insertar.");
    });
  }

  search()
  {
    this.cargarArrayAux();
    if(this.textSearch=="")
    {
      this.arrayAxu=undefined;
      return;
    }
    this.arrayAxu=this.arrayAnimes.filter((x : Anime)=> x.NombreAnime.toUpperCase().indexOf(this.textSearch.toUpperCase())>-1);
  }

  cargarArrayAuxAmigo()
  {
    let animeAmigo = new AnimeAmigosBD();
    animeAmigo.Usuario=this.encrypy.decode(keyEncrypt,sessionStorage.getItem('egs'));
    this.animeUserService.Seleccionar(animeAmigo).subscribe((x: AnimeAmigosBD[])=>this.arraryAnimesAmigo=x);
  }

  comprobante(Anime : Anime)
  {
    let request = false;
    for(let i=0; i<this.arraryAnimesAmigo.length;i++)
    {
      if(this.arraryAnimesAmigo[i].Nombre==Anime.NombreAnime)
      {
        request=true;
        break;
      }
    }
    return request;
  }

  borrarAnime(Anime : Anime)
  {
    for(let i=0; i<this.arraryAnimesAmigo.length;i++)
    {
      if(this.arraryAnimesAmigo[i].Nombre==Anime.NombreAnime)
      {
        let animeAmigo = new AnimeAmigosBD();
        animeAmigo.id=this.arraryAnimesAmigo[i].id;
        animeAmigo.Usuario=this.encrypy.decode(keyEncrypt,sessionStorage.getItem('egs'));
        this.animeUserService.Delete(animeAmigo).subscribe(x=>{
          if(x==true)
          {
            this.cargarArrayAuxAmigo();
            this.toastr.success("Se elimino con exito.");
          }
          else
            this.toastr.error("Se produjo algun tipo de error al eliminarlo");
        });
        break;
      }
    }
  }
}
