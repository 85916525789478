import { Component, OnInit } from '@angular/core';
import { AnimeAmigosBD } from 'src/app/clases/anime-amigos-bd';
import { AnimeAmigosService } from 'src/app/Servicios/animesAmigos/anime-amigos.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Estado } from 'src/app/clases/estado';
import { EstadoService } from 'src/app/Servicios/Estados/estado.service';
import { ToastrService } from 'ngx-toastr';
import { host, keyEncrypt } from 'src/app/data';
import { SimpleCrypt } from 'ngx-simple-crypt';

@Component({
  selector: 'app-perfil-anime-user',
  templateUrl: './perfil-anime-user.component.html',
  styleUrls: ['./perfil-anime-user.component.css']
})
export class PerfilAnimeUserComponent implements OnInit {

  private encrypy = new SimpleCrypt();
  public animeSelect : AnimeAmigosBD = new AnimeAmigosBD();
  public estadoSelect : Estado = new Estado();
  constructor(private animeUserService : AnimeAmigosService,
              private route : ActivatedRoute,
              private roter : Router,
              private estadoService : EstadoService,
              private toastr : ToastrService) { }
  public hostName = host;

  ngOnInit(): void 
  {
    this.cargarAnime();
  }

  cargarAnime()
  {
    if(this.animeSelect.id = parseInt(this.route.snapshot.paramMap.get('id')))
    {
      this.animeSelect.Usuario=this.encrypy.decode(keyEncrypt,sessionStorage.getItem('egs'));
      this.animeUserService.Seleccionar(this.animeSelect).subscribe((x : AnimeAmigosBD)=>{
        if(x[0])
        {
          this.animeSelect=x[0];
          this.animeSelect.Usuario=this.encrypy.decode(keyEncrypt,sessionStorage.getItem('egs'));
          this.cargarEstados();
        }
        else
          this.roter.navigate(['Error404']);
      });
    }
    else
      this.roter.navigate(['Error404']);
  }

  cargarEstados()
  {
    this.estadoSelect.IdEstado=this.animeSelect.Estado;
    this.estadoService.Seleccionar(this.estadoSelect).subscribe((x: Estado)=>this.estadoSelect=x[0]);
  }
  dropAnime()
  {
    this.animeUserService.Delete(this.animeSelect).subscribe(x=>{
      if(x==true)
      {
        this.toastr.success("Se elimino con exito.");
        this.roter.navigate(['/AnimesUsuarios']);
      }
      else
        this.toastr.error("Hubo algun problema al realizar la opracion, intentelo de nuevo mas tarde.");
    });
  }
}
