import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-pag',
  templateUrl: './footer-pag.component.html',
  styleUrls: ['./footer-pag.component.css']
})
export class FooterPagComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
