import { Component, OnInit, Input } from '@angular/core';
import { ListaAnimesService } from 'src/app/Servicios/ListasAnime/lista-animes.service';
import { ListaAnimes } from 'src/app/clases/lista-animes';
import { LineasListaService } from 'src/app/Servicios/LineaLista/lineas-lista.service';
import { LineaLista } from 'src/app/clases/linea-lista';
import { ToastrService } from 'ngx-toastr';
import { keyEncrypt } from 'src/app/data';
import { SimpleCrypt } from 'ngx-simple-crypt';

@Component({
  selector: 'app-modal-list-add',
  templateUrl: './modal-list-add.component.html',
  styleUrls: ['./modal-list-add.component.css']
})
export class ModalListAddComponent implements OnInit {
  
  private encrypy = new SimpleCrypt();
  @Input() idAnime;
  @Input() NombreAnime;
  ArrayLineas : LineaLista[] = [];
  listasArray : ListaAnimes[];

  constructor(private ListaService : ListaAnimesService,
    private lineasListaService : LineasListaService,
    private toastr : ToastrService) { }

  ngOnInit(): void 
  {
    this.cargarListasAnimes();
    this.cargarArrayListas();
  }

  cargarListasAnimes()
  {
    let listAnime = new ListaAnimes();
    listAnime.Usuario=this.encrypy.decode(keyEncrypt,sessionStorage.getItem('egs'));
    this.ListaService.Seleccionar(listAnime).subscribe((x: ListaAnimes[])=>this.listasArray=x);
  }

  cargarArrayListas()
  {
    let linea : LineaLista = new LineaLista();
    linea.idAnime=this.idAnime;
    this.lineasListaService.Seleccionar(linea).subscribe((x:LineaLista[])=>this.ArrayLineas=x);
  }

  comprobarChek(idLista : Number)
  {
    let request = false;
    for(let i=0; i<this.ArrayLineas.length;i++)
    {
      if(this.ArrayLineas[i].idLista==idLista)
      {
        request=true;
        break;
      }
    }
    return request;
  }

  dropAddToList(lista : ListaAnimes, event)
  {
    let lineaLista = new LineaLista();
    lineaLista.idLista=lista.idLista;
    lineaLista.idAnime=this.idAnime;
    lineaLista.NombreAnime=this.NombreAnime;
    if(event==true)
    {
      this.lineasListaService.Insertar(lineaLista).subscribe(x=>{
        if(x)
          this.toastr.success("Se guardo con exito.");
        else
          this.toastr.error("Se produjo algun error al relaizar la opracion, vuelvalo a intentar mas tarde");
      });
    }
    else
    {
      this.lineasListaService.Delete(lineaLista).subscribe(x=>{
        if(x)
          this.toastr.success("Se elimino con exito.");
        else
          this.toastr.error("Se produjo algun error al relaizar la opracion, vuelvalo a intentar mas tarde");
      });
    }
  }
}
