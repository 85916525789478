import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ListaAnimesService } from 'src/app/Servicios/ListasAnime/lista-animes.service';
import { ListaAnimes } from 'src/app/clases/lista-animes';
import { ToastrService } from 'ngx-toastr';
import { keyEncrypt } from 'src/app/data';
import { SimpleCrypt } from 'ngx-simple-crypt';

@Component({
  selector: 'app-create-lista-modal',
  templateUrl: './create-lista-modal.component.html',
  styleUrls: ['./create-lista-modal.component.css']
})
export class CreateListaModalComponent implements OnInit {

  private encrypy = new SimpleCrypt();
  public NombreLista : string;
  @Output() insertEvente = new EventEmitter();
  constructor(private listaService : ListaAnimesService,
              private toastr : ToastrService) { }

  ngOnInit(): void {
  }

  CrearLista()
  {
    let lista = new ListaAnimes();
    lista.NombreLista=this.NombreLista;
    lista.Usuario=this.encrypy.decode(keyEncrypt,sessionStorage.getItem('egs'));
    this.listaService.Insertar(lista).subscribe(x=>{
      if(x)
      {
        this.insertEvente.emit();
        this.toastr.success("Se creo con exito.");
      }
      else
        this.toastr.error("Se produjo algun error al crear la lista.");
    });
  }
}
