import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperSearchService {

  msgBusq : string;
  private msgSource = new Subject<string>(); 
  public msgObserbable$ = this.msgSource.asObservable();
  constructor() { }

  msgSend(msgBusq : string) {
    this.msgBusq=msgBusq;
    this.msgSource.next(this.msgBusq);
  }
}
