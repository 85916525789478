import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Estado } from 'src/app/clases/estado';
import { host } from 'src/app/data';

@Injectable({
  providedIn: 'root'
})
export class EstadoService {

  private url = host+"/animes3.0.1API/Model/Estados/";

  constructor(private http : HttpClient) { }

  Seleccionar(estado : Estado)
  {
    return this.http.post(`${this.url}SeleccionarEstado.php`,JSON.stringify(estado));
  }
}
