import { Component, OnInit, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cerrar-session-modal',
  templateUrl: './cerrar-session-modal.component.html',
  styleUrls: ['./cerrar-session-modal.component.css']
})
export class CerrarSessionModalComponent implements OnInit {

  @Output() clickYes=new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  clickYesfunction()
  {
    this.clickYes.emit();
  }
}
