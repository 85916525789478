import { Component, OnInit } from '@angular/core';
import { AnimeService } from 'src/app/Servicios/animes/anime.service';
import { Anime } from 'src/app/clases/anime';
import { EstadoService } from 'src/app/Servicios/Estados/estado.service';
import { Estado } from 'src/app/clases/estado';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { host, keyEncrypt } from 'src/app/data';
import { SimpleCrypt } from 'ngx-simple-crypt';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  private encrypy = new SimpleCrypt();
  public animesArray : Anime[];
  public animeAuxArry : Anime[] = [];
  private estado : Estado = new Estado();
  public EstadosArray : Estado[];
  public animeSeleted : Anime = new Anime();
  public estadoSelectFiltro : FormControl = new FormControl('0');
  public ordenFiltro : FormControl = new FormControl('2');
  p: number = 1;
  public hostName=host;

  constructor(private animeService : AnimeService,private EstadoService : EstadoService,
              private spinner : NgxSpinnerService) { }

  ngOnInit(): void 
  {
    this.cargarAnimes();
    this.CargarEstados();
    this.spinner.show();
    window.onload=()=>
    {
      this.spinner.hide();
    };
  }

  cargarArrayAux()
  {
    this.animeAuxArry=this.animesArray;
  }

  cargarAnimes()
  {
    this.animeSeleted.Usuario=this.encrypy.decode(keyEncrypt,sessionStorage.getItem('egs'));
    this.animeSeleted.Estado=0;
    this.animeSeleted.IdAnime=0;
    this.animeService.seleccionar(this.animeSeleted).subscribe((x : Anime[])=>{
      this.animesArray=x;
      this.cargarArrayAux();
      this.filtroOrden();
    });
  }

  public CargarEstados()
  {
    this.estado.IdEstado=0;
    this.EstadoService.Seleccionar(this.estado).subscribe((x : Estado[])=>this.EstadosArray=x);
  }

  filtroEstado()
  {
    this.p=1;
    this.cargarArrayAux();
    if(this.estadoSelectFiltro.value>0)
      this.animeAuxArry=this.animesArray.filter(x=>x.Estado==this.estadoSelectFiltro.value);
  }

  filtroOrden()
  {
    for(let i=0; i<this.animeAuxArry.length; i++)
    {
      for(let j=0; j<this.animeAuxArry.length-1; j++)
      {
        if(this.ordenFiltro.value==2)
        {
          if(this.animeAuxArry[j].IdAnime<this.animeAuxArry[j+1].IdAnime)
          {
            let anime : Anime = this.animeAuxArry[j];
            this.animeAuxArry[j]=this.animeAuxArry[j+1];
            this.animeAuxArry[j+1]=anime;
          }
        }
        else
        {
          if(this.animeAuxArry[j].IdAnime>this.animeAuxArry[j+1].IdAnime)
          {
            let anime : Anime = this.animeAuxArry[j];
            this.animeAuxArry[j]=this.animeAuxArry[j+1];
            this.animeAuxArry[j+1]=anime;
          }
        }
      }
    }
  }
}