<div class="wrapper">
  <app-cookies-messaje></app-cookies-messaje>
  <app-nav-top *ngIf="sessionLoad"></app-nav-top>
  <app-side-bar-left *ngIf="sessionLoad"></app-side-bar-left>
  <div class="content-wrapper" [ngClass]="{'m-0': !sessionLoad}" style="min-height: 1215px;">
    <router-outlet></router-outlet>
  </div>
</div>
<app-footer-pag *ngIf="sessionLoad"></app-footer-pag>
 <app-control-sidebar></app-control-sidebar>
