import { Component, OnInit } from '@angular/core';
import { UsuarioServiceService } from 'src/app/Servicios/usuario/usuario-service.service';
import { ToastrService } from 'ngx-toastr';
import { Usuario } from 'src/app/clases/usuario';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { Mail } from 'src/app/clases/mail';
import { Md5 } from 'ts-md5';
import { SimpleCrypt } from 'ngx-simple-crypt';
import { keyEncrypt } from 'src/app/data';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  private encrypt = new SimpleCrypt();
  public request : boolean = false;
  public email : string;
  public codigoUser : string;
  private userSelected = new Usuario();
  private codigo : string;

  constructor(private userService : UsuarioServiceService,
              private toastr : ToastrService,
              private route : Router,
              private appComponent : AppComponent,
              private cookie : CookieService) { }

  ngOnInit(): void {
  }

  ComprobarEmail()
  {
    let user = new Usuario();
    user.correo=this.email;
    this.userService.Seleccionar(user).subscribe((x: Usuario[])=>{
      if(x.length>0)
      {
        this.request=true;
        this.userSelected=x[0];
        this.EnviarCodigo();
      }
      else
        this.toastr.error("No hay ninguna cuenta registrada con ese correo electrónico.");
    });
  }

  random(logitud : number)
  {
    let random = Math.floor(Math.random()*(logitud-1));
    return random;
  }

  generardorCodigo()
  {
    let letrasArray=['A','B','c','D','E','F','G','H','i','j','K','L','M','N','O','P','q','x','y','Z'];
    let arrayNumeros = [0,1,2,3,4,5,6,7,8,9];
    let codigo : string = letrasArray[this.random(letrasArray.length)] +
                          arrayNumeros[this.random(arrayNumeros.length)]+
                          letrasArray[this.random(letrasArray.length)] +
                          arrayNumeros[this.random(arrayNumeros.length)]+
                          letrasArray[this.random(letrasArray.length)]+
                          arrayNumeros[this.random(arrayNumeros.length)]+
                          letrasArray[this.random(letrasArray.length)];
    return codigo;
  }

  EnviarCodigo()
  {
    this.codigo=this.generardorCodigo();
    let mail = new Mail();
    mail.correo=this.email;
    mail.from="animesadmin@animels.es";
    mail.contenido=this.codigo;
    this.userService.forgetPassword(mail).subscribe(x=>{
      if(x)
        this.toastr.success("Se le envio el codigo a su correo electronico.");
    });
  }

  comprobarCodigo()
  {
    let md5 = new Md5();
    if(this.codigoUser==this.codigo)
    {
      let passwordNew = md5.appendStr(this.codigo).end().toString();
      this.userSelected.Contrasena=passwordNew;
      this.userService.updatePassword(this.userSelected).subscribe(x=>{
        if(x)
          this.login();
        else
          this.toastr.error("Hubo algun tipo de problema en el proceso, por favor intentelo mas tarde.");
      })
    }
    else
      this.toastr.error("Codigo incorrecto.");
  }

  login()
  {
    this.cookie.set("eg&",this.encrypt.encode(keyEncrypt,this.email));
    this.appComponent.sessionState();
    this.route.navigate(['/']);
  }

}
