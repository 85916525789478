import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AnimeService } from 'src/app/Servicios/animes/anime.service';
import { Anime } from 'src/app/clases/anime';
import { EstadoService } from 'src/app/Servicios/Estados/estado.service';
import { Estado } from 'src/app/clases/estado';
import { GestorImagenesUserServiceService } from 'src/app/Servicios/img/gestor-imagenes-user-service.service';
import { ToastrService } from 'ngx-toastr';
import { host, keyEncrypt } from 'src/app/data';
import { SimpleCrypt } from 'ngx-simple-crypt';


@Component({
  selector: 'app-anime',
  templateUrl: './anime.component.html',
  styleUrls: ['./anime.component.css']
})
export class AnimeComponent implements OnInit {

  private encrypy = new SimpleCrypt();
  public animeSelect : Anime = new Anime();
  public estadoSelect : Estado = new Estado();

  constructor(private routes : ActivatedRoute, 
              private animeService : AnimeService,
              private estadoService : EstadoService,
              private gestorImg : GestorImagenesUserServiceService,
              private toastr : ToastrService,
              private rote : Router) { }
  public hostName = host;

  ngOnInit(): void 
  {
    this.cargarAnime();
  }

  cargarAnime()
  {
    if(this.animeSelect.IdAnime=parseInt(this.routes.snapshot.paramMap.get('id')))
    {
      this.animeSelect.Usuario=this.encrypy.decode(keyEncrypt,sessionStorage.getItem('egs'));
      this.animeSelect.Estado=0;
      this.animeService.seleccionar(this.animeSelect).subscribe((x: Anime)=>{
        if(x[0])
        {
          this.animeSelect = x[0];
          this.cargarEstado(x[0].Estado);
        }
        else
          this.rote.navigate(['Error404']);
      });
    }
    else
      this.rote.navigate(['Error404']); 
  }

  cargarEstado(estado : Number)
  {
    this.estadoSelect.IdEstado=estado;
    this.estadoService.Seleccionar(this.estadoSelect).subscribe((x:Estado)=>this.estadoSelect=x[0]);
  }

  dropAnime()
  {
    this.animeService.Delete(this.animeSelect).subscribe(x=>{
      if(x==true)
      {
        this.gestorImg.BorrarImagen(this.animeSelect.img).subscribe();
        this.toastr.success("Se elimino con exito");
        this.rote.navigate(['/']);
      }
      else
        this.toastr.error("Hubo algun error al intentar eliminarlo, intentelo de nuevo mas tarde");
    });
  }
}
