<div class="login-page">
    <div class="login-box">
        <div class="login-logo">
          <a href="javascript:void(0)"><b>Anime</b>LS</a>
        </div>
        <!-- /.login-logo -->
        <div class="card">
          <div class="card-body login-card-body">
            <p class="login-box-msg">Logueate para iniciar tu Sesion</p>
      
            <form (submit)="sigIn()">
              <div class="input-group mb-3">
                <input type="email" class="form-control" name="email" [(ngModel)]="usuario.correo" placeholder="Email">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div class="input-group mb-3">
                <input type="password" class="form-control" name="password" placeholder="Password" [(ngModel)]="usuario.Contrasena">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-8">
                  <div class="icheck-primary">
                    <input class="mr-2" type="checkbox" name="remember" [(ngModel)]="check">
                    <label for="remember">
                      Recuerdame
                    </label>
                  </div>
                </div>
                <!-- /.col -->
                <div class="col-4">
                  <button type="submit" class="btn btn-primary btn-block">Sign In</button>
                </div>
                <!-- /.col -->
              </div>
            </form>
      
            <p class="mb-1">
              <a [routerLink]="['/Forget']">Has olvidado tu contraseña?</a>
            </p>
            <p class="mb-0">
              <a [routerLink]="['/registro']"  class="text-center">Registrar un nuevo usuario</a>
            </p>
          </div>
          <!-- /.login-card-body -->
        </div>
      </div>
      <!-- /.login-box -->
</div>

