<!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper ml-0">
      <!-- Content Header (Page header) -->
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1>Perfil</h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="active" >Inicio</a></li>
                <li class="breadcrumb-item active">Perfil</li>
              </ol>
            </div>
          </div>
        </div><!-- /.container-fluid -->
      </section>
  
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-3">
  
              <!-- Profile Image -->
              <div class="card card-primary card-outline">
                <div class="card-body box-profile">
                  <div class="text-center">
                    <img class="profile-user-img img-fluid img-circle"
                         src="{{hostName}}/animes3.0.1API/{{usuarioSelected.img}}"
                         alt="User profile picture">
                  </div>
                  <h3 class="profile-username text-center">{{usuarioSelected.Nombre}}#{{usuarioSelected.idUsuario}}</h3>
  
                  <p class="text-muted text-center">{{usuarioSelected.correo}}</p>
  
                  <ul class="list-group list-group-unbordered mb-3">
                    <li class="list-group-item">
                        <b>Total Animes</b> <a class="float-right">{{NumeroAnimesEnLista.TotalAnimes}}</a>
                    </li>
                    <li class="list-group-item">
                      <b>Animes en Ver cuanto antes</b> <a class="float-right">{{NumeroAnimesEnLista.VerCuantoAntes}}</a>
                    </li>
                    <li class="list-group-item">
                      <b>Animes en Proximamente</b> <a class="float-right">{{NumeroAnimesEnLista.Proximamente}}</a>
                    </li>
                    <li class="list-group-item">
                      <b>Animes en Proceso</b> <a class="float-right">{{NumeroAnimesEnLista.EnProceso}}</a>
                    </li>
                    <li class="list-group-item">
                      <b>Animes en Finalizado</b> <a class="float-right">{{NumeroAnimesEnLista.Finalizado}}</a>
                    </li>
                  </ul>
                </div>
                <!-- /.card-body -->
              </div>
              <!-- /.card -->
            </div>
            <!-- /.col -->
            <div class="col-md-9">
              <div class="card">
                <div class="card-header p-2 row">
                  <ul class="nav nav-pills ml-2 col">
                    <li class="nav-item"><a class="nav-link active" href="#MisListas" data-toggle="tab" (click)="SerchShow=true">Mis Listas</a></li>
                    <li class="nav-item"><a class="nav-link" href="#settings" data-toggle="tab" (click)="SerchShow=false">Mi datos</a></li>
                    <li class="nav-item"><a class="nav-link" href="#password" data-toggle="tab" (click)="SerchShow=false">Cambiar contraseña</a></li>
                  </ul>

                  <form class="form-inline" *ngIf="SerchShow">
                    <div class="input-group">
                      <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" name="search" [(ngModel)]="Search" (input)="searchFilter()">
                      <div class="input-group-append">
                        <button class="btn btn-sidebar" type="submit">
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                  
                </div><!-- /.card-header -->
                <div class="card-body">
                  <div class="tab-content">
                    <div class="active tab-pane" id="MisListas">
                        <div class="col-12 row justify-content-end mb-3">
                            <h4 class="col">Mis listas.</h4>
                            <button class="btn btn-success" data-toggle="modal" data-target="#crearLista">Crear nueva lista</button>
                        </div>
                        <div class="container row justify-content-center">
                            <div class="row col-12 justify-content-em-center justify-content-sm-right justify-content-md-right">
                                  <div class="card col-xl-3 col-md-4 col-7 p-0 ml-5 opacityNull" *ngFor="let listas of arrayNombrePrueba | paginate: { itemsPerPage: 8, currentPage: p }" (click)="ListaSelected=listas">
                                    <a href="javascript:void(0)">
                                      <div class="card-header text-center bg-dark-opacity-absolute">
                                        <h4 class="m-0 letrahiden">{{listas.NombreLista}}</h4>
                                          <div class="dropdown position-absolute menuDroppdown">
                                            <i class="fas fa-ellipsis-v dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                              <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#dropList"><i class="fas fa-trash" style="color: #dc3545;"></i> Borrar</a>
                                            </div>
                                          </div>
                                      </div>
                                      <div class="card-body p-0">
                                        <a [routerLink]="['/Lista',listas.idLista,listas.NombreLista]">
                                          <img class=" m-0 card-img-bottom rounded-bottom" style="height: 13rem;" src="{{hostName}}/animes3.0.1API/users/img/imgNotValid.png" alt="img lista"/>
                                        </a>
                                      </div>
                                    </a>
                                  </div>
                            </div>
                        </div>
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>
                    <!-- /.tab-pane-Password -->
                    <div class="tab-pane" id="password" (submit)="UpdatePassword()">
                      <form class="form-horizontal" [formGroup]="formularioUpdatePassword">
                        <div class="form-group row">
                          <label for="inputName" class="col-sm-2 col-form-label">Old Password</label>
                          <div class="col-sm-10">
                            <input type="password" class="form-control" formControlName="oldPassword" placeholder="Old Password">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="inputName" class="col-sm-2 col-form-label">New Password</label>
                          <div class="col-sm-10">
                            <input type="password" class="form-control" formControlName="newPassword"  placeholder="New Password">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="inputName" class="col-sm-2 col-form-label">Repetir Password</label>
                          <div class="col-sm-10">
                            <input type="password" class="form-control" formControlName="RepitePassword" placeholder="Repetir Password">
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="offset-sm-2 col-sm-10">
                            <button type="submit"  class="btn btn-info">Actualizar</button>
                          </div>
                        </div>
                        </form>
                    </div>
                    <!-- /.tab-pane-Password -->
  
                    <div class="tab-pane" id="settings">
                      <form class="form-horizontal" [formGroup]="formularioUpdateUsuario">
                        <div class="form-group row">
                          <label for="inputName" class="col-sm-2 col-form-label">Name</label>
                          <div class="col-sm-10">
                            <input type="email" class="form-control" formControlName="Nombre" value="{{usuarioSelected.Nombre}}" placeholder="Nombre">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="inputEmail" class="col-sm-2 col-form-label">Feha Nacimiento</label>
                          <div class="col-sm-10">
                            <input type="date" class="form-control" formControlName="fechaNac" value="{{usuarioSelected.fechaNacimiento}}">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="inputSkills" class="col-sm-2 col-form-label">Img</label>
                          <ngx-dropzone class="col-sm-10" (change)="imagenUpdate($event)">
                            <ngx-dropzone-label>Arrastra tu imagen aqui</ngx-dropzone-label>
                            <ngx-dropzone-image-preview *ngFor="let f of imgFilePreview" [removable]="true" (removed)="imagenRemove()" [file]="f">
                                <ngx-dropzone-label class="text-white">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                            </ngx-dropzone-image-preview>
                          </ngx-dropzone>
                        </div>

                        <div class="form-group row">
                          <div class="offset-sm-2 col-sm-10">
                            <button type="submit" class="btn btn-info" (click)="updateUserDates()">Actualizar</button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <!-- /.tab-pane -->
                  </div>
                  <!-- /.tab-content -->
                </div><!-- /.card-body -->
              </div>
              <!-- /.nav-tabs-custom -->
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div><!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
    <app-create-lista-modal (insertEvente)="cargarListaAnimes()"></app-create-lista-modal>
    <app-drop-list-modal (clickYesEvent)="borrarLista()"></app-drop-list-modal>