import { Component, OnInit} from '@angular/core';
import { UsuarioServiceService } from 'src/app/Servicios/usuario/usuario-service.service';
import { Usuario } from 'src/app/clases/usuario';
import { HelperServiceService } from 'src/app/Servicios/conmutadores/helper-service.service';
import { host, keyEncrypt } from 'src/app/data';
import { CookieService } from 'ngx-cookie-service';
import { SimpleCrypt } from 'ngx-simple-crypt';

@Component({
  selector: 'app-side-bar-left',
  templateUrl: './side-bar-left.component.html',
  styleUrls: ['./side-bar-left.component.css']
})
export class SideBarLeftComponent implements OnInit
{
  private encrypy = new SimpleCrypt();
  private UpdateProfileChecked = false;
  public usuarioSelect = new Usuario();
  constructor(private usuarioService : UsuarioServiceService,
              private Helper : HelperServiceService,
              private cookie : CookieService) { }
  public hostPrincipal = host;

  ngOnInit(): void 
  {
    this.Helper.clickObserbable$.subscribe(response=>
    {
      this.UpdateProfileChecked=response;
      if(this.UpdateProfileChecked)
        this.sessionState();
    })
    this.sessionState();
  }

  public sessionState()
  {
    if(sessionStorage.getItem('egs'))
    {
      this.usuarioSelect.correo=this.encrypy.decode(keyEncrypt,sessionStorage.getItem('egs'));
      this.usuarioService.Seleccionar(this.usuarioSelect).subscribe((x : Usuario[])=>this.usuarioSelect=x[0]);
    }
  }

  cerrarSession()
  {
    sessionStorage.clear();
    this.cookie.delete('eg&','/');
    window.location.reload();
  }
}
