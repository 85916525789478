<div class="container p-5">
    <header class="row">
        <h3 class="col">{{listaSelect.NombreLista}}</h3>
        <div class="dropleft">
            <button class="btn" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="javascript:void(0)" (click)="editar=true"><i class="fas fa-pen mr-2"></i> Editar</a>
            </div>
        </div>
        <div class="row col-12 bg-white rounded p-2 shadow-sm align-items-center" *ngIf="editar">
            <label class="col-12 col-lg-2">Nombre Lista</label>
            <input class="col" type="text" name="nombreLista" [(ngModel)]="listaSelect.NombreLista">
            <button class="btn"><i class="fas fa-check" style="color: green;" (click)="updateList()"></i></button>
            <button class="btn"><i class="fas fa-times" style="color: maroon;" (click)="editar=false; ngOnInit()"></i></button>
        </div>
    </header>
    <div class="card bg-white mt-4 row flex-row justify-content-center justify-content-lg-start p-3">
        <section class="row pb-3 border-bottom col-12">
            <button type="button" class="btn btn-info m-0" (click)="atrasButon()"><i class="fas fa-arrow-left"></i></button>
            <div _ngcontent-mxd-c56 id="searchList" class="input-group input-group-sm col justify-content-end">
                <input _ngcontent-mxd-c56 type="search" placeholder="Search" name="Search" aria-label="Search" class="form-control form-control-navbar ng-untouched ng-pristine ng-valid col-9 col-md-3" ng-reflect-name="Search" (input)="search($event.target.value)">
                <div _ngcontent-mxd-c56 class="input-group-append">
                    <button _ngcontent-mxd-c56 type="button" class="btn btn-navbar">
                        <i _ngcontent-mxd-c56 class="fas fa-search"></i>
                    </button>
                </div>
            </div>
        </section>
        <div type="button" class="card col-8 col-sm-6 col-md-4 col-lg-3 col-xl-2 p-0 ml-2 mt-3" *ngFor="let anime of arrayAnimeList | paginate: { itemsPerPage: 10, currentPage: p }">
            <a [routerLink]="['/Anime', anime.idAnime, anime.NombreAnime]">
                <div class="ribbon-wrapper ribbon-lg">
                    <div class="ribbon bg-success text-sm" [ngClass]="{'bg-danger' : anime.idEstado==4, 'bg-info' : anime.idEstado==3, 'bg-warning' : anime.idEstado==2}">
                        <small class="font-weight-bold">{{anime.Estado}}</small>
                    </div>
                </div>
                <div class="card-header p-0">
                    <img class="img-fluid imgAnime rounded-top w-100" src="{{hostName}}/animes3.0.1API/{{anime.img}}" alt="img"/>
                </div>
                <div class="card-body text-dark">
                    <h2>{{anime.NombreAnime}}</h2>
                </div>
            </a>
        </div>
        <section class="row justify-content-center mt-5 col-12">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </section>
    </div>
</div>