export class AnimeAmigosBD 
{
    public id : Number;
    public Nombre : string;
    public Estado : Number;
    public Descripcion : string;
    public Propietario : string;
    public Usuario : string;
    public img : string;
}
