import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ListaAnimes } from '../../clases/lista-animes';
import { host } from 'src/app/data';

@Injectable({
  providedIn: 'root'
})
export class ListaAnimesService 
{
  private url = host+"/animes3.0.1API/Model/ListasAnimes/";

  constructor(private http : HttpClient) { }

  Seleccionar (lista : ListaAnimes)
  {
    return this.http.post(`${this.url}SeleccionarLista.php`,JSON.stringify(lista));
  }

  Insertar (lista : ListaAnimes)
  {
    return this.http.post(`${this.url}InsertarLista.php`,JSON.stringify(lista));
  }

  Update (lista : ListaAnimes)
  {
    return this.http.post(`${this.url}UpdateLista.php`,JSON.stringify(lista));
  }

  Delete (lista : ListaAnimes)
  {
    return this.http.post(`${this.url}DeleteLista.php`,JSON.stringify(lista));
  }
}
