import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Usuario } from 'src/app/clases/usuario';
import { host } from 'src/app/data';

@Injectable({
  providedIn: 'root'
})
export class GestorImagenesUserServiceService {

  usuer  = new Usuario();
  private url = host+"/animes3.0.1API/";

  constructor(private http : HttpClient) { }

  subirImagen(imagen : File)
  {
    const img = new FormData();
    img.append('imgAnimePage',imagen,sessionStorage.getItem('Email'));
    return this.http.post(`${this.url}subirImg.php`,img);
  }

  BorrarImagen(ruta : string)
  {
    this.usuer.img=ruta;
    return this.http.post(`${this.url}BorrarImg.php`,JSON.stringify(this.usuer));
  }
}
