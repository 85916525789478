<!-- Content Header (Page header) -->
<section class="content-header">

  <!-- Main content -->
  <section class="content">
    <div class="error-page">
      <h2 class="headline text-warning"> 404</h2>
      <div class="error-content">
        <h3><i class="fas fa-exclamation-triangle text-warning"></i> Oops! Pagina no encontrada.</h3>
        <p>
          No pudimos encontrar lo que esta buscando.
          Mientras tanto puedes <a [routerLink]="['/']" >volver al inicio</a>.
        </p>
      </div>
      <!-- /.error-content -->
    </div>
    <!-- /.error-page -->
  </section>
  <!-- /.content -->
