import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LineaLista } from 'src/app/clases/linea-lista';
import { host } from 'src/app/data';

@Injectable({
  providedIn: 'root'
})
export class LineasListaService {

  private url= host+"/animes3.0.1API/Model/LineasLista/";

  constructor(private http : HttpClient) { }

  Seleccionar(LineaLista : LineaLista)
  {
    return this.http.post(`${this.url}SeleccionarLineas.php`,JSON.stringify(LineaLista));
  }

  Insertar(LineaLista : LineaLista)
  {
    return this.http.post(`${this.url}InsertarLinea.php`,JSON.stringify(LineaLista));
  }

  Delete(LineaLista : LineaLista)
  {
    return this.http.post(`${this.url}DeleteLinea.php`,JSON.stringify(LineaLista));
  }
}
