import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Usuario } from 'src/app/clases/usuario';
import { host } from 'src/app/data';
import { Mail } from 'src/app/clases/mail';

@Injectable({
  providedIn: 'root'
})
export class UsuarioServiceService 
{
  private url = host+"/animes3.0.1API/Model/usuario/";

  constructor(private http: HttpClient) { }

  IniciarSession(usuario : Usuario)
  {
    return this.http.post(`${this.url}IniciarSession.php`,JSON.stringify(usuario));
  }

  Seleccionar(usuario : Usuario)
  {
    return this.http.post(`${this.url}SeleccionarUsuario.php`,JSON.stringify(usuario));
  }

  insertar(usuario : Usuario)
  {
    return this.http.post(`${this.url}InsertarUsuario.php`,JSON.stringify(usuario));
  }

  Update(usuario : Usuario)
  {
    return this.http.post(`${this.url}UpdateUser.php`,JSON.stringify(usuario));
  }

  updatePassword(usuario : Usuario)
  {
    return this.http.post(`${this.url}updatePassword.php`,JSON.stringify(usuario));
  }

  forgetPassword(mail : Mail)
  {
    return this.http.post(`${this.url}forgetPassword.php`,JSON.stringify(mail));
  }
}
