import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-drop-list-modal',
  templateUrl: './drop-list-modal.component.html',
  styleUrls: ['./drop-list-modal.component.css']
})
export class DropListModalComponent implements OnInit {

  @Output() clickYesEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  clickYes()
  {
    this.clickYesEvent.emit();
  }

}
