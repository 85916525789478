import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { GestorImagenesUserServiceService } from 'src/app/Servicios/img/gestor-imagenes-user-service.service';
import { Anime } from 'src/app/clases/anime';
import { EstadoService } from 'src/app/Servicios/Estados/estado.service';
import { Estado } from 'src/app/clases/estado';
import * as clasicEditor from '@ckeditor/ckeditor5-build-classic';
import { AnimeService } from 'src/app/Servicios/animes/anime.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-update-anime',
  templateUrl: './modal-update-anime.component.html',
  styleUrls: ['./modal-update-anime.component.css']
})
export class ModalUpdateAnimeComponent implements OnInit {

  @Input() an : Anime;
  @Output() updateEvente = new EventEmitter();
  EstadoSelect : Estado = new Estado();
  EstadoArray : Estado[] = [];
  public imgFilePreview : File[] = [];
  private imgFile : File;
  private oldimg : string;
  public editor = clasicEditor;
  private imgOld : string;

  constructor(private gestorImg : GestorImagenesUserServiceService,
              private estadosService : EstadoService,
              private animeService : AnimeService,
              private toastr : ToastrService) { }

  ngOnInit(): void {
    this.cargarEstados();
  }

  cargarEstados()
  {
    this.EstadoSelect.IdEstado=0;
    this.estadosService.Seleccionar(this.EstadoSelect).subscribe((x: Estado[])=>this.EstadoArray = x);
  }

  update()
  {
    if(this.imgFile)
    {
      this.imgOld=this.an.img;
      this.gestorImg.subirImagen(this.imgFile).subscribe((x : any)=>{
        if(x!=false)
        {
          this.an.img=x;
          console.log("img new: "+this.an.img+"Img old: "+this.imgOld);
          this.imagenRemove();
          this.updateDatosUser();
        }
        else
          this.toastr.error("Hubo algun tipo de fallo al subir la imagen, por lo tanto no se actualizo");
      });
    }
    else
      this.updateDatosUser();
  }

  updateDatosUser()
  {
    this.animeService.Update(this.an).subscribe((x : any)=>{
      if(x==true)
      {
        this.toastr.success("Se actualizo con exito.");
        this.gestorImg.BorrarImagen(this.imgOld).subscribe();
        this.updateEvente.emit();
      }
      else
      {
        this.toastr.error("Hubo algun problema al actualizar, compruebe que no halla usado carácteres como: (')");
        this.updateEvente.emit();
        if(this.imgFile)
        {
          this.gestorImg.BorrarImagen(this.an.img).subscribe();
          this.an.img=this.imgOld;
        }
      }
    });
  }

  imagenUpdate(event)
  {
    if(this.imgFilePreview.length>0)
      this.imagenRemove();
    this.imgFilePreview.push(...event.addedFiles);
    this.imgFile=this.imgFilePreview[0];
  }

  imagenRemove()
  {
    this.imgFilePreview = [];
    this.imgFile=undefined;
  }

}
