import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { AnimeAmigosBD } from 'src/app/clases/anime-amigos-bd';
import { EstadoService } from 'src/app/Servicios/Estados/estado.service';
import { Estado } from 'src/app/clases/estado';
import { AnimeAmigosService } from 'src/app/Servicios/animesAmigos/anime-amigos.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-update-anime-amigo',
  templateUrl: './modal-update-anime-amigo.component.html',
  styleUrls: ['./modal-update-anime-amigo.component.css']
})
export class ModalUpdateAnimeAmigoComponent implements OnInit {

  @Input() animeUsuario : AnimeAmigosBD;
  @Output() animeUpdate  = new EventEmitter();
  Estados : Estado = new Estado();
  EstadoArray : Estado[];

  constructor(private estadoService : EstadoService,
              private animeUserService : AnimeAmigosService,
              private toastr : ToastrService) { }

  ngOnInit(): void 
  {
    this.CargarEstados();
  }

  CargarEstados()
  {
    this.estadoService.Seleccionar(this.Estados).subscribe((x:Estado[])=>this.EstadoArray=x);
  }

  update()
  {
    this.animeUserService.Update(this.animeUsuario).subscribe(x=>{
      if(x==true)
      {
        this.toastr.success("Se actulizo correctamente.");
        this.animeUpdate.emit();
      }
      else
        this.toastr.error("Hubo algun error al acatualizar, porfavor intentelo de nuevo mas tarde.");
    });
  }
  
}
