<div class="modal fade" id="modalUpdateAnime" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-dark">
          <h5 class="modal-title" id="exampleModalLongTitle">Update Anime</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form class="form-horizontal">
                <div class="form-group row">
                  <label for="inputName" class="col-sm-2 col-form-label">Nombre Anime</label>
                  <div class="col-sm-10">
                    <input type="email" class="form-control" name="nombreAnime" [(ngModel)]="an.NombreAnime" placeholder="Nombre Anime">
                  </div>
                </div>
                <div class="col"><hr></div>
                <div class="form-group row">
                  <label for="inputEmail" class="col-sm-2 col-form-label">Descripcion</label>
                  <div class="col-sm-10">
                    <ckeditor [(ngModel)]="an.DescripcionAnime" name="descripcion" [editor]="editor" ></ckeditor>
                  </div>
                </div>
                <div class="form-group row">
                    <label for="inputState" class="col-sm-2 col-form-label">Estado</label>
                    <select class="form-control col-sm-10" name="estado" [(ngModel)]="an.Estado">
                      <option [selected]="estado.IdEstado==an.Estado" *ngFor="let estado of EstadoArray" [value]="estado.IdEstado" (click)="an.Estado=estado.IdEstado">{{estado.NombreEstado}}</option>
                    </select>
                </div>
                <div class="col"><hr></div>
                <div class="form-group row">
                  <label for="inputSkills" class="col-sm-2 col-form-label">Img</label>
                  <ngx-dropzone class="col-sm-10" (change)="imagenUpdate($event)">
                    <ngx-dropzone-label>Arrastra tu imagen aqui</ngx-dropzone-label>
                    <ngx-dropzone-image-preview *ngFor="let f of imgFilePreview" [removable]="true" (removed)="imagenRemove()" [file]="f">
                        <ngx-dropzone-label class="text-white">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-image-preview>
                  </ngx-dropzone>
                </div>
            </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Cerra</button>
          <button type="button" class="btn btn-info" (click)="update()">Actualizar</button>
        </div>
      </div>
    </div>
</div>