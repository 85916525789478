import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Error404Component } from './Layouts/error404/error404.component';
import { InicioComponent } from './Layouts/inicio/inicio.component';
import { LoginComponent } from './Layouts/login/login.component';
import { RegistroComponent } from './Layouts/registro/registro.component';
import { PerfilUserComponent } from './Layouts/perfil-user/perfil-user.component';
import { SearchComponent } from './Layouts/search/search.component';
import { AnimeComponent } from './Layouts/anime/anime.component';
import { InsertarAnimeComponent } from './Layouts/insertar-anime/insertar-anime.component';
import { AnimeAmigosComponent } from './Layouts/anime-amigos/anime-amigos.component';
import { PerfilAnimeUserComponent } from './Layouts/perfil-anime-user/perfil-anime-user.component';
import { PerfilListaComponent } from './Layouts/perfil-lista/perfil-lista.component';
import { ForgetPasswordComponent } from './Layouts/forget-password/forget-password.component';


const routes: Routes = [
  {path:'Error404', component:Error404Component},
  {path:'Login', component: LoginComponent},
  {path:'', component:InicioComponent},
  {path:'registro',component:RegistroComponent},
  {path:'Perfil',component:PerfilUserComponent},
  {path:'Search',component:SearchComponent},
  {path: 'Anime/:id/:nombreAnime',component:AnimeComponent},
  {path:'Insertar', component:InsertarAnimeComponent},
  {path:'AnimesUsuarios',component:AnimeAmigosComponent},
  {path:'AnimeUsario/:id/:nombreAnime',component:PerfilAnimeUserComponent},
  {path:'Lista/:id/:nombreLista',component:PerfilListaComponent},
  {path:'Forget',component:ForgetPasswordComponent},
  {path:'**',redirectTo:'Error404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
