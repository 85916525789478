<div class="modal fade" id="dropList" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body p-1 pt-3">
            <div class="container row justify-content-center">
                <div class="row col-12 justify-content-center">
                    <i class="far fa-question-circle mb-3" style="color: #339af0; font-size: 6rem"></i>
                </div>
                <p class="text-lg text-center text-dark font-weight-bold">¿Seguro que desea eliminar esta lista?</p>
                <p class="text-secondary font-weight-bold">Si la elimina no podra desacer la operacion</p>
            </div>
        </div>
        <div class="row justify-content-center modal-footer border-0">
          <button type="button" class="btn btn-danger text-lg px-5" data-dismiss="modal">No</button>
          <button type="button" class="btn btn-info text-lg px-5" data-dismiss="modal" (click)="clickYes()">Si</button>
        </div>
      </div>
    </div>
</div>