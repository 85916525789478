<div class="modal" id="modalPolicaCookies" tabindex="-1" role="dialog" aria-labelledby="modalPolicaCookies" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h5 class="modal-title">POLITICA DE COOKIES</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div id="contentModalPolitica" class="modal-body overflow-auto">
            <p>Las cookies son pequeñas cantidades de información que se almacenan en el</p>
            <p>navegador utilizado por cada usuario para que el servidor recuerde cierta </p>
            <p>información que posteriormente pueda utilizar.</p>
            <h5>TIPOS DE COOKIES QUE UTILIZAMOS</h5>
            <p>Esta página web utiliza cookies tecnicas propias que son aquellas </p>
            <p>que son necesarias para el correcto funcionamiento de</p>
            <p>el sitio web.</p>
            <p>En este caso las Cookies son utilizadas con el fin</p>
            <p>de perdurar la sesion del usuario en el sitio web.</p>
            <p>Su duracion se vera incrementada a 1 año en funcion de si</p>
            <p>es seleccionada la casilla de verficaión "recuerdame"</p>
            <p>de nuestro login.</p>
            <table class="table">
                <thead>
                  <tr>
                    <th scope="col">COOKIE</th>
                    <th scope="col">Duración</th>
                    <th scope="col">Tipo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>eg&</td>
                    <td>Sesión/1 años</td>
                    <td>Técnica (uso necesario)</td>
                  </tr>
                </tbody>
            </table>
            <p>Si desea más información más sobre los tipos de cookies de seguimiento y análisis </p>
            <p>de datos de Google <a href="https://policies.google.com/technologies/cookies#types-of-cookies" target="_blank">haga click aquí</a></p>
            <p>Para informarse sobre cómo eliminar las cookies de su explorador:</p>
            <ul>
              <li><a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias" target="_blank">Firefox</a></li>
              <li><a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank">Chrome</a></li>
              <li><a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target="_blank">Safari</a></li>
            </ul>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>