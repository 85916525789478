import { Component, OnInit } from '@angular/core';
import { LineasListaService } from 'src/app/Servicios/LineaLista/lineas-lista.service';
import { ActivatedRoute } from '@angular/router';
import { LineaLista } from 'src/app/clases/linea-lista';
import { ListaAnimes } from 'src/app/clases/lista-animes';
import { host, keyEncrypt } from 'src/app/data';
import { SimpleCrypt } from 'ngx-simple-crypt';
import { ListaAnimesService } from 'src/app/Servicios/ListasAnime/lista-animes.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-perfil-lista',
  templateUrl: './perfil-lista.component.html',
  styleUrls: ['./perfil-lista.component.css']
})
export class PerfilListaComponent implements OnInit {

  private encrypt = new SimpleCrypt();
  public editar : boolean = false;
  listaSelect : ListaAnimes = new ListaAnimes();
  arrayPrincipal : LineaLista[];
  arrayAnimeList : LineaLista[];
  public p;
  public hostName=host;

  constructor(private lineaListaService : LineasListaService,
              private routing : ActivatedRoute,
              private listasService : ListaAnimesService,
              private toastr : ToastrService) { }

  ngOnInit(): void 
  {
    this.listaSelect.idLista=parseInt(this.routing.snapshot.paramMap.get('id'));
    this.listaSelect.Usuario= this.encrypt.decode(keyEncrypt,sessionStorage.getItem('egs'));
    this.cargarLista();
    this.cargarLineasLista();
    this.p=1;
  }

  cargarLineasLista()
  {
    let lineaLista = new LineaLista();
    lineaLista.idLista=this.listaSelect.idLista;
    console.log(lineaLista);
    this.lineaListaService.Seleccionar(lineaLista).subscribe((x : LineaLista[])=>{
      this.arrayPrincipal=x;
      this.cargarArrayAxu();
    });
  }

  cargarLista()
  {
    this.listasService.Seleccionar(this.listaSelect).subscribe((x: ListaAnimes[])=>this.listaSelect=x[0]);
  }

  cargarArrayAxu()
  {
    this.arrayAnimeList=this.arrayPrincipal;
  }

  atrasButon()
  {
    history.go(-1);
  }

  search(text : string)
  {
    this.cargarArrayAxu();
    this.arrayAnimeList=this.arrayAnimeList.filter(x=>x.NombreAnime.toUpperCase().indexOf(text.toUpperCase())>-1);
  }

  updateList()
  {
    this.listasService.Update(this.listaSelect).subscribe(x=>{
      if(x)
      {
        this.toastr.success("Se actualizo con exito.");
        this.editar=false;
      }
      else
        this.toastr.error("Hubo algun problema al actualizar, intentelo de nuevo mas tarde.");
    });
  }
}
