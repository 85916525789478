export class LineaLista 
{
    public idLinea : Number;
    public idAnime : Number;
    public NombreAnime : string;
    public idLista : Number;
    public img : string;
    public idEstado : Number;
    public Estado : string;
}
