import { Component, OnInit } from '@angular/core';
import { AnimeAmigosBD } from 'src/app/clases/anime-amigos-bd';
import { AnimeAmigosService } from 'src/app/Servicios/animesAmigos/anime-amigos.service';
import { EstadoService } from 'src/app/Servicios/Estados/estado.service';
import { Estado } from 'src/app/clases/estado';
import { host, keyEncrypt } from 'src/app/data';
import { FormControl } from '@angular/forms';
import { SimpleCrypt } from 'ngx-simple-crypt';

@Component({
  selector: 'app-anime-amigos',
  templateUrl: './anime-amigos.component.html',
  styleUrls: ['./anime-amigos.component.css']
})
export class AnimeAmigosComponent implements OnInit {
  private encrypy = new SimpleCrypt();
  public p  = 1;
  private estado : Estado = new Estado();
  public estadoArray : Estado[];
  public animesAmigosArrayPrincipal : AnimeAmigosBD[];
  public animesAmigosArray : AnimeAmigosBD[];
  private animeAmigo : AnimeAmigosBD = new AnimeAmigosBD();
  public estadoSelectFiltro : FormControl = new FormControl('0');
  public ordenFiltro : FormControl = new FormControl('2');
  constructor(private animeAmigosService : AnimeAmigosService,
              private estadoService : EstadoService) { }
  public hostName = host;

  ngOnInit(): void 
  {
    this.p=1;
    this.cargarAnimesAmigos();
    this.cargarEstado();
  }

  cargarAnimesAmigos()
  {
    this.animeAmigo.Usuario=this.encrypy.decode(keyEncrypt,sessionStorage.getItem('egs'));
    this.animeAmigosService.Seleccionar(this.animeAmigo).subscribe((x : AnimeAmigosBD[])=>{
      this.animesAmigosArrayPrincipal=x;
      this.cargarArrayAux();
      this.filtroOrden();
    });
  }

  cargarArrayAux()
  {
    this.animesAmigosArray=this.animesAmigosArrayPrincipal;
  }

  search(value)
  {
    this.cargarArrayAux();
    this.animesAmigosArray=this.animesAmigosArray.filter(x=>x.Nombre.toUpperCase().indexOf(value.toUpperCase())>-1);
  }

  cargarEstado()
  {
    this.estadoService.Seleccionar(this.estado).subscribe((x:Estado[])=>this.estadoArray=x);
  }

  filtroEstado()
  {
    this.p=1;
    this.cargarArrayAux();
    if(this.estadoSelectFiltro.value>0)
      this.animesAmigosArray=this.animesAmigosArray.filter(x=>x.Estado==this.estadoSelectFiltro.value);
  }

  filtroOrden()
  {
    for(let i=0; i<this.animesAmigosArray.length; i++)
    {
      for(let j=0; j<this.animesAmigosArray.length-1; j++)
      {
        if(this.ordenFiltro.value==2)
        {
          if(this.animesAmigosArray[j].id<this.animesAmigosArray[j+1].id)
          {
            let anime : AnimeAmigosBD = this.animesAmigosArray[j];
            this.animesAmigosArray[j]=this.animesAmigosArray[j+1];
            this.animesAmigosArray[j+1]=anime;
          }
        }
        else
        {
          if(this.animesAmigosArray[j].id>this.animesAmigosArray[j+1].id)
          {
            let anime : AnimeAmigosBD = this.animesAmigosArray[j];
            this.animesAmigosArray[j]=this.animesAmigosArray[j+1];
            this.animesAmigosArray[j+1]=anime;
          }
        }
      }
    }
  }
}
