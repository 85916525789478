<div class="container pt-5">
    <section class="pl-5 row">
        <div class="card col-11 col-md-3 p-0 mr-5">
            <div class="card-header p-0">
                <img id="imgProfile" class="img-fluid rounded-top w-100" src="{{hostName}}/animes3.0.1API/{{animeSelect.img}}" alt="img"/>
            </div>
            <div class="card-body p-0 pl-1 pr-1">
                <h2 class="text-center pt-2 pb-1" >{{animeSelect.NombreAnime}}</h2>
                <p class="text-lg w-100 text-center rounded mb-1 p-2 font-weight-bold" [ngClass]="{'bg-success': estadoSelect.IdEstado==1, 'bg-warning' : estadoSelect.IdEstado==2,
                                                                                                'bg-info' : estadoSelect.IdEstado==3,'bg-danger' : estadoSelect.IdEstado==4}" ><i class="fas fa-tv mt-2 float-left"></i>{{estadoSelect.NombreEstado}}</p>
                <section class="row justify-content-center m-0">
                    <p type="button" class="btn btn-dark text-lg col-5 mr-1 mt-0 mb-1 p-2" data-toggle="modal" data-target="#modalDropAnime"><i class="fas fa-trash"></i></p>
                    <p type="button" class="btn btn-primary text-lg col-6 ml-1 mt-0 mb-1 p-2" data-toggle="modal" data-target="#modalUpdateAnime" ><i class="far fa-edit"></i></p>
                </section>
                <p type="button" class="btn btn-secondary w-100 mb-1" data-toggle="modal" data-target="#modalAddList"><i class="fas fa-list mt-1 float-left"></i> Añdir a Lista</p>
            </div>
        </div>
        <div class="card descripcion col-11 col-md p-3 scrollPersonalizado">
            <h2>Descripcion: </h2>
            <div [innerHTML]="animeSelect.DescripcionAnime"></div>
        </div>
    </section>
</div>
<app-drop-anime-modal (clickYes)="dropAnime()"></app-drop-anime-modal>
<app-modal-update-anime [an]="animeSelect" (updateEvente)="cargarAnime()"></app-modal-update-anime>
<app-modal-list-add [idAnime]="animeSelect.IdAnime" [NombreAnime]="animeSelect.NombreAnime"></app-modal-list-add>