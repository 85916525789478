import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Usuario } from 'src/app/clases/usuario';
import { UsuarioServiceService } from 'src/app/Servicios/usuario/usuario-service.service';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { CookieService } from 'ngx-cookie-service';
import {SimpleCrypt} from "ngx-simple-crypt";
import { keyEncrypt } from 'src/app/data';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit 
{
  private encript = new SimpleCrypt();
  public usuario : Usuario = new Usuario();
  public check : boolean = false;
  constructor(private toastr : ToastrService,
              private usuarioService : UsuarioServiceService,
              private route : Router,
              private appComp : AppComponent,
              private cookie : CookieService) { }

  ngOnInit(): void 
  {
    if(this.cookie.check('eg&'))
      this.route.navigate(["/"]);
  }

  sigIn() : void
  {
    this.usuarioService.IniciarSession(this.usuario).subscribe(x=>{
      if(x==true)
      {
        this.toastr.success('Se inicio sesion correctamente.');
        sessionStorage.setItem('egs',this.encript.encode(keyEncrypt,this.usuario.correo));
        if(!this.check)
          this.cookie.set('eg&',this.encript.encode(keyEncrypt,this.usuario.correo));
        else
          this.cookie.set('eg&',this.encript.encode(keyEncrypt,this.usuario.correo),365);
        this.route.navigate(["/"]);
        this.appComp.ngOnInit();
      }
      else
      {
        this.toastr.error('Se produjo algun tipo de error al iniciar la sesion.');
      }
    });
  }

}
