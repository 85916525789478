import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { UsuarioServiceService } from 'src/app/Servicios/usuario/usuario-service.service';
import { Usuario } from 'src/app/clases/usuario';
import { GestorImagenesUserServiceService } from 'src/app/Servicios/img/gestor-imagenes-user-service.service';
import { FormGroup,FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HelperServiceService } from 'src/app/Servicios/conmutadores/helper-service.service';
import {Md5} from 'ts-md5/dist/md5';
import { ListaAnimesService } from 'src/app/Servicios/ListasAnime/lista-animes.service';
import { ListaAnimes } from 'src/app/clases/lista-animes';
import { host, keyEncrypt } from 'src/app/data';
import { SimpleCrypt } from 'ngx-simple-crypt';
import { CookieService } from 'ngx-cookie-service';
import { Anime } from 'src/app/clases/anime';
import { AnimeService } from 'src/app/Servicios/animes/anime.service';

@Component({
  selector: 'app-perfil-user',
  templateUrl: './perfil-user.component.html',
  styleUrls: ['./perfil-user.component.css']
})
export class PerfilUserComponent implements OnInit 
{
  public NumeroAnimesEnLista : any = [
                          {"TotalAnimes": 0},
                          {"VerCuantoAntes": 0},
                          {"EnProceso": 0},
                          {"Proximamente":0},
                          {"Finalizado":0}
                        ];
  public numeroAnimes : Number = 0;
  public animesArray : Anime[] = [];
  private encrypy = new SimpleCrypt();
  public p;
  formularioUpdateUsuario = new FormGroup({
    Nombre : new FormControl(''),
    fechaNac : new FormControl(''),
  });

  formularioUpdatePassword = new FormGroup({
    oldPassword : new FormControl(),
    newPassword : new FormControl(),
    RepitePassword : new FormControl()
  });

  public usuarioSelected = new Usuario();
  public arrayNombrePrincipal : ListaAnimes[];
  public ListaSelected : ListaAnimes = new ListaAnimes();
  public arrayNombrePrueba = [];
  public SelectedPage = 0;
  public Search : string = "";
  public SerchShow = true;
  public imgFilePreview : File[] = [];
  private imgFile : File;
  private oldimg : string;
  @Output() UpdateProfile = new EventEmitter();

  constructor(private userService : UsuarioServiceService,
              private gestorImg : GestorImagenesUserServiceService,
              private toastr : ToastrService,
              private Helper : HelperServiceService,
              private ListaService : ListaAnimesService,
              private cookie : CookieService,
              private animesService : AnimeService) { }
  public hostName=host;

  ngOnInit(): void 
  {
    this.p=1;
    this.cargarDatosUser();
    this.cargarListaAnimes();
    this.cargarAnimesArray();
  }

  cargarDatosUser()
  {
    this.usuarioSelected.correo=this.encrypy.decode(keyEncrypt,sessionStorage.getItem('egs'));
    this.userService.Seleccionar(this.usuarioSelected).subscribe((x:Usuario[])=>this.usuarioSelected=x[0]);
  }

  cargarPagina()
  {
    this.arrayNombrePrueba = this.arrayNombrePrincipal;
  }

  cargarAnimesArray()
  {
    let anime : Anime = new Anime();
    anime.Usuario=this.usuarioSelected.correo;
    this.animesService.seleccionar(anime).subscribe((x: Anime[])=>{
      this.animesArray=x;
      this.NumeroAnimesPorEstado();
    });
  }

  public NumeroAnimesPorEstado()
  {
    this.NumeroAnimesEnLista.TotalAnimes=this.animesArray.length;
    this.NumeroAnimesEnLista.VerCuantoAntes=this.animesArray.filter(x=>x.Estado==1).length;
    this.NumeroAnimesEnLista.EnProceso=this.animesArray.filter(x=>x.Estado==3).length;
    this.NumeroAnimesEnLista.Proximamente=this.animesArray.filter(x=>x.Estado==2).length;
    this.NumeroAnimesEnLista.Finalizado=this.animesArray.filter(x=>x.Estado==4).length;
  }

  cargarListaAnimes()
  {
    let animeLista = new ListaAnimes();
    animeLista.Usuario=this.encrypy.decode(keyEncrypt,sessionStorage.getItem('egs'));
    this.ListaService.Seleccionar(animeLista).subscribe((x : ListaAnimes[])=>{
      this.arrayNombrePrincipal=x;
      this.cargarPagina();
    });
  }

  borrarLista()
  {
    this.ListaService.Delete(this.ListaSelected).subscribe(x=>{
      if(x)
      {
        this.toastr.success("Borrado con exito.");
        this.cargarListaAnimes();
      }
      else
        this.toastr.error("Hubo algun problema al eliminar la lista");
    })
  }

  searchFilter()
  {
    this.cargarPagina();
    this.arrayNombrePrueba=this.arrayNombrePrincipal.filter((x:ListaAnimes)=>x.NombreLista.toUpperCase().indexOf(this.Search.toUpperCase())>-1);
  }

  updateUserDates()
  {
    
    if(this.imgFile!=undefined)
    {
      this.oldimg=this.usuarioSelected.img;
      this.gestorImg.subirImagen(this.imgFile).subscribe((x:any)=>{
        if(x!=false)
        {
          this.usuarioSelected.img=x;
          this.updateUserDatesNotimg();
        }
      });
    }
    else
    this.updateUserDatesNotimg();
  }

  updateUserDatesNotimg()
  {
    if(this.formularioUpdateUsuario.controls.Nombre.value!="")
    {
      this.usuarioSelected.Nombre=this.formularioUpdateUsuario.controls.Nombre.value;
    }
    if(this.formularioUpdateUsuario.controls.fechaNac.value!="")
    {
      this.usuarioSelected.fechaNacimiento=this.formularioUpdateUsuario.controls.fechaNac.value;
    }

    this.userService.Update(this.usuarioSelected).subscribe(x=>{
      if(x==true)
      {
        this.toastr.success('Se actualizo con exito.');
        this.imagenRemove();
        this.Helper.clickCheck(true);
        if(this.oldimg!="users/img/defaultUserImg.jpg")
          this.gestorImg.BorrarImagen(this.oldimg).subscribe();
      }
      else
      {
        this.toastr.error('Hubo algun error al actualizar');
        this.gestorImg.BorrarImagen(this.usuarioSelected.img).subscribe();
        if(this.oldimg!=undefined)
          this.usuarioSelected.img = this.oldimg;
      }
    });
  }

  UpdatePassword()
  {
    let md5 = new Md5();
    let mensaje = "";
    try
    {
      let oldPassword = md5.appendStr(this.formularioUpdatePassword.controls.oldPassword.value).end();
      md5=new Md5();
      let newPassword = md5.appendStr(this.formularioUpdatePassword.controls.newPassword.value).end();
      md5=new Md5();
      let RepitePassword = md5.appendStr(this.formularioUpdatePassword.controls.RepitePassword.value).end();
      if(oldPassword==this.usuarioSelected.Contrasena)
      {
        if(newPassword==RepitePassword)
        {
          if(newPassword!=oldPassword)
          {
            this.usuarioSelected.Contrasena=newPassword.toString();
            this.userService.updatePassword(this.usuarioSelected).subscribe(x=>{
              if(x)
              {
                this.toastr.success("Contraseña actualizada.");
                this.cookie.delete('eg&');
                setInterval(function()
                {
                  sessionStorage.clear();
                  window.location.reload();
                },1000) 
              }
              else
              {
                this.toastr.error("Error al actualizar la contraseña");
                this.usuarioSelected.Contrasena=oldPassword.toString();
              }
                
            });
            
            return;
          }
          else
            mensaje = "La nueva contraseña no puede ser igual que la antigua.";
        }
        else
          mensaje="'Repit Password' no coincide con la nueva contraseña."
      }
      else
        mensaje="La contraseña antigua no coincide.";
      this.toastr.error(mensaje);
    }
    catch(x)
    {
      this.toastr.error("Deben de estar todos los campos rellenos.");
    }
  }

  imagenUpdate(event)
  {
    if(this.imgFilePreview.length>0)
      this.imagenRemove();
    this.imgFilePreview.push(...event.addedFiles);
    this.imgFile=this.imgFilePreview[0];
  }

  imagenRemove()
  {
    this.imgFilePreview = [];
    this.imgFile=undefined;
  }
}