import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperSearchService } from 'src/app/Servicios/conmutadores/helper-search.service';

@Component({
  selector: 'app-nav-top',
  templateUrl: './nav-top.component.html',
  styleUrls: ['./nav-top.component.css']
})
export class NavTopComponent implements OnInit 
{
  constructor(private routes : Router, private HelperSearch : HelperSearchService) { }

  ngOnInit(): void {
  }

  searchSend(text)
  {
    if(text!="")
    {
      this.HelperSearch.msgSend(text);
      this.routes.navigate(["/Search"]);
    }
    else
      history.go(-1);
  }
}
