<div class="card rounded-0" id="cajacookies" *ngIf="cookieMessajeStatus()">
    <div class="card-body p-0 pl-3">
        <p><button class="pull-right btn btn-success text-light mr-2" (click)="cookieAcept()"><i class="fa fa-check"></i> Aceptar y cerrar éste mensaje</button>
            <button class="pull-right btn btn-dark text-light" data-toggle="modal" data-target="#ConfigurarCookieModal"><i class="fas fa-cog"></i> Configurar</button>
            Éste sitio web usa cookies, si permanece aquí acepta su uso.
            Puede leer más sobre el uso de cookies en nuestra <a href="javascript:void(0)" data-toggle="modal" data-target="#modalPolicaCookies">política de Cookies</a>.
        </p>
    </div>
</div>
<textoLegalCokies></textoLegalCokies>
<configurarCookiesModal (clickGuardarEvent)="cookieAcept()"></configurarCookiesModal>