<div class="login-page">
    <div class="login-box">
        <div class="login-logo">
          <a href="javascript:void(0)"><b>Anime</b>LS</a>
        </div>
        <!-- /.login-logo -->
        <div class="card">
          <div class="card-body login-card-body">
            <p class="login-box-msg">Si no recuerdas tu contraseña podemos enviarte un codigo al correo para que puedas volver a iniciar sesion.</p>
      
            <form>
              <div class="input-group mb-3" *ngIf="!request">
                <input type="email" class="form-control" name="email" [(ngModel)]="email" placeholder="Email">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div class="input-group mb-3" *ngIf="request">
                <input type="text" class="form-control" name="codigo" [(ngModel)]="codigoUser" placeholder="Introduce el codigo">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-key"></span>
                  </div>
                </div>
              </div>
              <p class="m-0 mb-2 p-0" *ngIf="request">
                <a class="m-0 p-0" href="javascript:void(0)" (click)="EnviarCodigo()" >Volver a enviar correo</a>
              </p>
                <!-- /.col -->
                <div class="col-12" *ngIf="!request">
                  <button type="submit" class="btn btn-primary btn-block" (click)="ComprobarEmail();">Enviar Codigo</button>
                </div>

                <div class="col-12" *ngIf="request">
                    <button type="submit" class="btn btn-primary btn-block" (click)="comprobarCodigo()">Comprobar Codigo</button>
                  </div>
                <!-- /.col -->
            </form>
      
            <p class="mb-1 mt-1">
              <a [routerLink]="['/Login']" >ya soy usuario</a>
            </p>
            <p class="mb-0">
              <a [routerLink]="['/registro']"  class="text-center">Registrar un nuevo usuario</a>
            </p>
          </div>
          <!-- /.login-card-body -->
        </div>
      </div>
      <!-- /.login-box -->
</div>

