import { Component, OnInit, EventEmitter,Output } from '@angular/core';

@Component({
  selector: 'configurarCookiesModal',
  templateUrl: './configurar-cookies.component.html',
  styleUrls: ['./configurar-cookies.component.css']
})
export class ConfigurarCookiesComponent implements OnInit {

  constructor() { }
  @Output() clickGuardarEvent = new EventEmitter();

  ngOnInit(): void {
  }


  clickGuardar()
  {
    this.clickGuardarEvent.emit();
  }
}
