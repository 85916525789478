import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Usuario } from 'src/app/clases/usuario';
import { UsuarioServiceService } from 'src/app/Servicios/usuario/usuario-service.service';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {

  public RegisterFormGorup : FormGroup= new FormGroup({
    UserName : new FormControl(),
    email : new FormControl(),
    password : new FormControl(),
    repitePassword : new FormControl(),
    fechaNacimiento : new FormControl(),
    checkTerminos : new FormControl()
  });

  constructor(private toastr : ToastrService,
              private usuarioService : UsuarioServiceService) { }

  ngOnInit(): void {
  }

  onSubmit()
  {
    if(this.RegisterFormGorup.value.checkTerminos)
    {
      if(this.RegisterFormGorup.value.password!="" && this.RegisterFormGorup.value.password!=null)
      {
        if(this.RegisterFormGorup.value.password==this.RegisterFormGorup.value.repitePassword)
        {
          if((this.RegisterFormGorup.value.UserName!="" && this.RegisterFormGorup.value.email!="" && this.RegisterFormGorup.value.fechaNacimiento!="") && (this.RegisterFormGorup.value.UserName!=null && this.RegisterFormGorup.value.email!=null && this.RegisterFormGorup.value.fechaNacimiento!=null))
          {
            this.registro();
          }
          else
            this.toastr.error("Todos los campos deben de estar rellenos.");
        }
        else
          this.toastr.error("Las contraseñas no coinciden.");
      }
      else
        this.toastr.error("Todos los campos deben de estar rellenos.");
    }
    else
      this.toastr.error("Debe acceptar la terminos para poder registrarse.");
  }

  registro()
  {
    let usuario : Usuario = new Usuario();
    usuario.Nombre=this.RegisterFormGorup.value.UserName;
    usuario.correo=this.RegisterFormGorup.value.email;
    usuario.fechaNacimiento=this.RegisterFormGorup.value.fechaNacimiento;
    usuario.Contrasena=this.RegisterFormGorup.value.password;
    this.usuarioService.insertar(usuario).subscribe(x=>{
      if(x)
        this.toastr.success("Tu cuenta se creo con exito.");
      else
        this.toastr.error("Hubo algun error al crear la cuenta, por favor revise que los datos sean correctos.");
    });
  }
}
