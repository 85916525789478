import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AnimeAmigosBD } from 'src/app/clases/anime-amigos-bd';
import { host } from 'src/app/data';

@Injectable({
  providedIn: 'root'
})
export class AnimeAmigosService {

  private url = host+"/animes3.0.1API/Model/animesAmigo/";

  constructor(private http : HttpClient) { }

  Seleccionar(animeAmigo : AnimeAmigosBD)
  {
    return this.http.post(`${this.url}SeleccionarAnimeAmigo.php`,JSON.stringify(animeAmigo));
  }

  insert(animeAmigo : AnimeAmigosBD)
  {
    return this.http.post(`${this.url}InsertarAnimeAmigo.php`,JSON.stringify(animeAmigo));
  }

  Update(animeAmigo : AnimeAmigosBD)
  {
    return this.http.post(`${this.url}UpdateAnimeAmigo.php`,JSON.stringify(animeAmigo));
  }
  
  Delete(animeAmigo : AnimeAmigosBD)
  {
    return this.http.post(`${this.url}DeleteAnimeAmigo.php`,JSON.stringify(animeAmigo));
  }
}
